/**
 * Redirects to delete page with signatures.
 * @param {{
 *  type: string;
 *  page: string;
 *  objects: any[]
 * }} deleteObject 
 * @returns {never}
 */
export function toDeletePage(navigate, deleteObject) {
    window.sessionStorage.setItem("toDelete", JSON.stringify(deleteObject));
    navigate("/destroy")

}
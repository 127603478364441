import React from 'react';

function MarginFooter() {
    return(
        <div className="marginFooter">

        </div>
    )
}

export default MarginFooter;
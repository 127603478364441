import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import MarginFooter from "../components/marginFooter";

function Roomselect() {

    const [rooms, setRooms] = useState([]);

    useEffect(() =>{
        document.body.style.overflow = "auto";
        const fetchData = async () => {
            try{
            const res = await axios.get("room")
            setRooms(res.data)
            } catch (err) {
                console.log(err)
            }
        };
        fetchData();
    }, []);

    return(
        <div className="bodyContent">
            <div className="centered selectRoomCenter">
            <h2 className="header">Select Room</h2>
            <Link to={`/newRoom`}>
                <input type="button" value="New Room" className="purpleBtn extraGridBtn" />
            </Link>
            <br />

                <div className="roomSelectGrid">

                    {/* Room id and links should come from database */}

                    {rooms.map((room) => {
                        return(
                        <Link key={room.roomid} to={`/room/${room.roomid}`} >
                            <div className="purpleBox greenColor gridItem">
                                <p className="linkText">{room.roomid}</p>
                            </div>
                        </Link>
                        )
                    })}
                </div>
                <MarginFooter/>
        </div>
        </div>
    )
}

export default requireAuth(Roomselect);
import React, { useEffect, useState, useMemo } from "react";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/FileDownload';

import { ExportToCsv } from 'export-to-csv';
import { useLocation, useNavigate } from "react-router-dom";




function PlantSelect() {

  const location = useLocation();
  const navigate = useNavigate();

  const [plants, setPlants] = useState([]);
  useEffect(() => {
    axios.get("plant")
      .then(res => setPlants(res.data))
      .catch(err => console.log(err));
  }, []);

  const [countAll, setCountAll] = useState([]);
  useEffect(() => {
    axios.get("plant/count/all")
      .then(res => setCountAll(res.data))
      .catch(err => console.log(err));
  }, []);

  const [countActive, setCountActive] = useState([]);
  useEffect(() => {
    axios.get("plant/count/active")
      .then(res => setCountActive(res.data))
      .catch(err => console.log(err));
  }, []);

  const [countRemoved, setCountRemoved] = useState([]);
  useEffect(() => {
    axios.get("plant/count/removed")
      .then(res => setCountRemoved(res.data))
      .catch(err => console.log(err));
  }, []);

  const [varieties, setVarieties] = useState([]);
  useEffect(() => {
    axios.get("plant/varieties")
      .then(res => setVarieties(res.data))
      .catch(err => console.log(err));
  }, []);

  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    axios.get("room")
      .then(res => setRooms(res.data))
      .catch(err => console.log(err));
  }, []);

  const [plots, setPlots] = useState([]);
  useEffect(() => {
    axios.get("plot")
      .then(res => setPlots(res.data))
      .catch(err => console.log(err));
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'uid',
        header: 'UID',
      },
      {
        accessorKey: 'variety',
        header: 'Variety',
      },
      {
        accessorKey: 'sex',
        header: 'Sex',
      },
      {
        accessorKey: 'loc_x',
        header: 'Plot Column',
      },
      {
        accessorKey: 'loc_y',
        header: 'Plot Row',
      },
      {
        accessorKey: 'plotid',
        header: 'Plot ID',
      },
      {
        accessorKey: 'datecreated',
        header: 'Date Created',
        Cell: ({ cell }) => {
          const date = cell.row.original.datecreated;
          return date ? new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }) : 'NO DATE';
        }
      },
      {
        accessorKey: 'datedestroyed',
        header: 'Date Destroyed',
        Cell: ({ cell }) => {
          const date = cell.row.original.datedestroyed;
          return date ? new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }) : 'NO DATE';
        }
      },
    ],
    [],
  );


  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    fields: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(plants);
  };

  const handleDeletePlants = (e, rows) => {
    // console.log(JSON.stringify(rows));
    // console.log(JSON.stringify(rows.map((row)=> row.original)));

    const deleteObject = {
      type: "plant",
      page: location.pathname,
      objects: rows.map((row) => row.original.uid),
    }
    window.sessionStorage.setItem("toDelete", JSON.stringify(deleteObject));
    navigate("/destroy")
  };

  return (
    <div className="bodyContent">
      <label htmlFor="plants" className="subheader">Select Plants</label>

      <div className="plantData">
        <div className="value">
          <div className="purpleHeader">
            <p className="valueTitle">Total Plants</p>
          </div>
          <div className="valueBox">
            <p className="valueText">{countAll.count}</p>
          </div>
        </div>
        <div className="value">
          <div className="purpleHeader">
            <p className="valueTitle">Removed Plants</p>
          </div>
          <div className="valueBox">
            <p className="valueText">{countRemoved.count}</p>
          </div>
        </div>
        <div className="value">
          <div className="purpleHeader">
            <p className="valueTitle">Active Plants</p>
          </div>
          <div className="valueBox">
            <p className="valueText">{countActive.count}</p>
          </div>
        </div>
      </div>

      <div className="plantTableDiv">
        <MaterialReactTable columns={columns} data={plants} enableRowSelection enableColumnOrdering
          initialState={{
            showColumnFilters: true,
            pagination: { pageIndex: 0, pageSize: 100 }, sorting: [{ id: 'uid', desc: true }]
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={(e) => handleDeletePlants(e, table.getSelectedRowModel().rows)}
                startIcon={<DeleteIcon />}
                variant="contained"
              >
                Destroy Selected Plants
              </Button>
            </Box>
          )}
        />
      </div>
    </div>
  )
}

export default requireAuth(PlantSelect);
import React from "react";
import requireAuth from "../context/requireAuth";

function PlacePlot() {
    return(
        <div className="bodyContent">
            <h1 className="title">Place new plot</h1>
                <div className="editFormDiv">
                <form action="/home" method="post" className="editForm">
                    <label htmlFor="plotNo">Plot Number</label>
                    <input type="number" name="plotNo" id="plotNo" className="topInput editInput" />
                    <br />
                    <label htmlFor="roomNo">Room Number</label>
                    <select name="roomNo" id="roomNo" className="editInput editSelect">
                        <option value="1">48</option>
                        <option value="2">81</option>
                        <option value="3">82</option>
                    </select>
                    <br />
                    <label htmlFor="col">Column</label>
                    <input type="number" name="col" id="col" className="editInput" />
                    <br />
                    <label htmlFor="row">Row</label>
                    <input type="number" name="row" id="row" className="editInput" />
                    <br />
                    <label htmlFor="floor">Floor</label>
                    <input type="number" name="floor" id="floor" className="editInput" />

                    <input type="submit" value="Place Plot" className="maintenanceFormSubmit"/>
                </form>
                </div>
            </div>
    )
}

export default requireAuth(PlacePlot);
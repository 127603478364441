import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";

function requireAuth(Component) {
  function AuthenticatedComponent(props) {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!currentUser.isAuthenticated) {
        const currentLocation = location.pathname;
        sessionStorage.setItem("redirectTo", currentLocation);
        navigate("/login");
      }
    }, [currentUser.isAuthenticated]);

    return currentUser.isAuthenticated ? (
      <Component {...props} />
    ) : null;
  }

  return AuthenticatedComponent;
}

export default requireAuth;

import React from "react";
import { Link } from "react-router-dom";
import requireAuth from "../context/requireAuth";

function PlantOptions() {
    
    return(
        <div className="bodyContent">
                <h1 className="header">420 - Commercial - Options</h1>
                <div className="optionsGrid">
                    <Link to="/plantMaintenance" className="purpleBox optionsGridItem">
                        <p className="optionTitle">Plant Maintenance</p>
                    </Link>
                    <Link to="/placePlant" className="purpleBox optionsGridItem">
                        <p className="optionTitle">Place Plant</p>
                    </Link>
                    <div className="purpleBox optionsGridItem">
                        <p className="optionTitle">Motherplant</p>
                    </div>
                    <div className="purpleBox optionsGridItem">
                        <p className="optionTitle">Family Tree</p>
                    </div>
                    <div className="purpleBox optionsGridItem">
                        <p className="optionTitle">View Dashboard</p>
                    </div>
                    <div className="purpleBox optionsGridItem">
                        <p className="optionTitle">Edit Plant</p>
                    </div>
                    <div className="purpleBox optionsGridItem">
                        <p className="optionTitle">Remove Plant</p>
                    </div>
                </div>
            </div>
    )
}

export default requireAuth(PlantOptions);
import React, { useEffect, useState, useMemo } from "react";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/FileDownload';

import { ExportToCsv } from 'export-to-csv';
  



function Product() {

    const [product, setProduct] = useState([]);
    useEffect(() => {
        axios.get("product")
            .then(res => setProduct(res.data))
            .catch(err => console.log(err));
    }, []);

    const [countAll, setCountAll] = useState([]);
    useEffect(() => {
        axios.get("product/count")
            .then(res => setCountAll(res.data))
            .catch(err => console.log(err));
    }, []);

    const [countGramInHouse, setCountGram] = useState([]);
    useEffect(() => {
        axios.get("product/count/quantity/inhouse")
            .then(res => setCountGram(res.data))
            .catch(err => console.log(err));
    }, []);

    const [countGramDry, setCountGramDry] = useState([]);
    useEffect(() => {
        axios.get("product/count/quantity/dry")
            .then(res => setCountGramDry(res.data))
            .catch(err => console.log(err));
    }, []);

    const [countGramFresh, setCountGramFresh] = useState([]);
    useEffect(() => {
        axios.get("product/count/quantity/fresh")
            .then(res => setCountGramFresh(res.data))
            .catch(err => console.log(err));
    }, []);

    const columns = useMemo(
        () => [
          {
            accessorKey: 'upid',
            header: 'uPid',
          },
          {
            accessorKey: 'name',
            header: 'Name',
          },
          {
            accessorKey: 'datecreated',
            header: 'Date Created',
            Cell: ({ cell }) => {
              const date = cell.row.original.datecreated;
              return date ? new Date(date).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }) : 'NO DATE';
            }
          },
          {
            accessorKey: 'datedestroyed',
            header: 'Date Destroyed',
            Cell: ({ cell }) => {
              const date = cell.row.original.datedestroyed;
              return date ? new Date(date).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }) : 'NO DATE';
            }
          },
          {
            accessorKey: 'quantity',
            header: 'Quantity (g)',
          },
          {
            accessorKey: 'fresh',
            header: 'Fresh (Y/N)',
          },
          {
            accessorKey: 'dry',
            header: 'Dry (Y/N)',
          },
          {
            accessorKey: 'location',
            header: 'Location (Room)',
          },
        ],
        [],
      );
      

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        fields: columns.map((c) => c.header),
      };
      
      const csvExporter = new ExportToCsv(csvOptions);
      
      const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
      };
      
      const handleExportData = () => {
        csvExporter.generateCsv(product);
      };

      const handleDeleteProduct = (rows) => {
        // console.log(JSON.stringify(rows));
        // console.log(JSON.stringify(rows.map((row)=> row.original)));
        const body = {};
        var i = 1;
        rows.forEach((row) => body[`upid${i++}`] = row.original.upid);
        body[`size`] = rows.length;
        console.log(JSON.stringify(body));
      
            axios.post(`product/deleteproduct`, body )
            .then(response => {
              console.log(response.data);
              const size = response.data.size
              alert(`product(s) deleted`);
            })
            .catch(error => {
              console.error(error);
            });
      };

    return (
        <div className="bodyContent">
            <label htmlFor="plants" className="subheader">Select Products</label>

            <div className="plantData">
                <div className="value">
                    <div className="purpleHeader">
                        <p className="valueTitle">Quantity Inhouse (g)</p>
                    </div>
                    <div className="valueBox">
                        <p className="valueText">{countGramInHouse.totalGrams}</p>
                    </div>
                </div>
            </div>

            <div className="plantTableDiv">
                <MaterialReactTable columns={columns} data={product} enableRowSelection enableColumnOrdering 
                initialState={{ showColumnFilters: true ,
                                pagination: { pageIndex: 0, pageSize: 100 }}} 
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Export All Data
                      </Button>
                      <Button
                        disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Export Selected Rows
                      </Button>
                      <Button
                        disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() => handleDeleteProduct(table.getSelectedRowModel().rows)}
                        startIcon={<DeleteIcon />}
                        variant="contained"
                      >
                        Delete Selected Products
                      </Button>
                    </Box>
                  )}
                />
            </div>
        </div>
    )
}

export default requireAuth(Product);
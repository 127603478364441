import React from "react";
import requireAuth from "../context/requireAuth";

function PlacePlant() {
    return(
        <div className="bodyContent">
            <h1 className="title">Place Plant</h1>
                <div className="editFormDiv">
                <form action="/home" method="post" className="editForm">
                <label htmlFor="roomNo">Room Number</label>
                    <select name="roomNo" id="roomNo" className="editInput editSelect">
                        <option value="1">48</option>
                        <option value="2">81</option>
                        <option value="3">82</option>
                    </select>
                    <br />
                    <label htmlFor="plotNo">Plot Number</label>
                    <select name="plotNo" id="plotNo" className="editInput editSelect">
                        <option value="1">1411</option>
                        <option value="2">1412</option>
                        <option value="3">1441</option>
                    </select>
                    <br />
                    

                    <input type="submit" value="Place Plant" className="maintenanceFormSubmit"/>
                </form>
                </div>
            </div>
    )
}

export default requireAuth(PlacePlant);
import React, { useState, useEffect } from "react";
import { Link, redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import { toDeletePage } from '../lib/toDeletePage';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { MaintenanceSelector } from "../components/MaintenanceSelector";
import { FaTimes } from "react-icons/fa";

function PlantModel({ selectingModeActive, plant, rowIndex, colIndex, handlePlantDivClick, handlePlantSelectClick }) {
  const [selected, setSelected] = useState(false);

  const handlePlantClick = (event) => {
    if (selectingModeActive) {
      setSelected(!selected);
      handlePlantSelectClick(!selected, plant)
    } else {
      handlePlantDivClick(event)
    }
  }

  useEffect(() => {
    setSelected(false);
  }, [selectingModeActive])

  let colorClass = "";

  switch (plant.alarmlevel) {
    case 1: {
      colorClass = "mediumAlarm"
      break;
    }
    case 2: {
      colorClass = "severeAlarm"
      break;
    }
    default: {}
  }

  if (selectingModeActive) {
    colorClass = "GridSelectingColor"
  }

  if (selected) {
    colorClass = "GridSelectedColor"
  }

  return (
    <div
      key={`location_[${colIndex},${rowIndex}]`}
      className={`GridItem ${colorClass}`}
      data-col={colIndex + 1}
      data-row={rowIndex + 1}
      data-plantid={plant.uid}
      onClick={handlePlantClick}
    >
      {plant.uid} - {plant.variety}
    </div>
  )
}

function Plot() {
  const [plants, setPlants] = useState([]);
  const [plot, setPlot] = useState({});
  const [loading, setLoading] = useState(false);
  const [plantInPlot, setPlantInPlot] = useState([]);
  const [plantsNotInPlot, setplantsNotInPlot] = useState([]);
  const [NoPlantShowModal, setNoPlantShowModal] = useState(false);
  const [PlantShowModal, setPlantShowModal] = useState(false);
  const [selectedCol, setSelectedCol] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedUid, setSelectedUid] = useState(false);
  const [inputs, setInputs] = useState({ uid: "" });
  const [grid, setGrid] = useState([]);
  const { id } = useParams();
  const [selectingModeActive, setSelectingModeActive] = useState(false);
  const [selectedUids, setSelectedUids] = useState(new Set());
  const [gridHtml, setGridHTML] = useState([]);

  const [bulkMaintenanceShowModal, setBulkMaintenanceShowModal] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [plantsRes, plotsRes] = await Promise.all([
        axios.get("plant/with-maintenance"),
        axios.get(`plot/plotid?plotid=${id}`),
      ]);
      setPlants(plantsRes.data);
      setPlot(plotsRes.data[0]);
      setLoading(false);
      // console.log(plants)
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    console.log('new plant fetch')
    fetchData();
  }, [id, NoPlantShowModal, PlantShowModal]);

  
  useEffect(() => {
    setPlantInPlot(plants.filter((plant) => plant.plotid == id));
    setplantsNotInPlot(plants.filter((plant) => plant.plotid == null && plant.datedestroyed == null));
  }, [plants])

  useEffect(() => {
    console.log('reload grid')
    fillGrid(plantInPlot)
  }, [plantInPlot]);

  useEffect(() => {
    setSelectedUids(new Set());
    setGridHTML(renderGridHtml())
  }, [grid, selectingModeActive])

  const handleSelectPlantsClick = (event) => {
    setSelectingModeActive(!selectingModeActive);
  }

  const handleNoPlantDivClick = (event) => {
    const col = event.target.getAttribute("data-col");
    const row = event.target.getAttribute("data-row");
    setSelectedCol(col);
    setSelectedRow(row);
    setNoPlantShowModal(true);
  };

  const handlePlantDivClick = (event) => {

    const col = event.target.getAttribute("data-col");
    const row = event.target.getAttribute("data-row");
    const uid = event.target.getAttribute("data-plantid");
    setSelectedCol(col);
    setSelectedRow(row);
    setSelectedUid(uid);
    setPlantShowModal(true);
  };

  const handlePlantSelectClick = (selected, plant) => {
    if (selected) {
      selectedUids.add(plant.uid)
      setSelectedUids(selectedUids)

    } else {
      selectedUids.delete(plant.uid)
      setSelectedUids(selectedUids)
    }
  }

  const handleRemoveBulkClick = (event) => {
    event.preventDefault();

    axios.delete("/plant", {
      data: {
        uids: Array.from(selectedUids)
      }
    }).then((res) => {
      setSelectedUids(new Set())
      alert(`Plants were removed`);
      fetchData();
    })
      .catch((error) => {
        console.error(error);
        alert("Something went wrong! Try Again");
      });

  }

  const hanldeBulkDestroyClick = (event) => {
    const deleteObject = {
      type: "plant",
      page: location.pathname,
      objects: Array.from(selectedUids),
    }
    setSelectedUids(new Set())
    window.sessionStorage.setItem("toDelete", JSON.stringify(deleteObject));
    navigate("/destroy")
  }

  const hanldeBulkMaintenanceClick = (event) => {
    setBulkMaintenanceShowModal(true)
  }

  const closeBulkMaintenanceClick = (event) => {
    setBulkMaintenanceShowModal(false)
  }

  const maintenanceModalCloseHandler = (event) => {
    setBulkMaintenanceShowModal(false)

    setSelectedUids(new Set());
    fetchData()
  }

  function fillGrid(plantsInPlot) {
    const newGrid = Array.from({ length: plot.size_y }, () =>
      Array.from({ length: plot.size_x }, () => null)
    );

    plantsInPlot.forEach((plant) => {
      const row = parseInt(plant.loc_y) - 1;
      const col = parseInt(plant.loc_x) - 1;

      if (newGrid[row] && newGrid[row][col]) {
        // console.warn(`Duplicate plant at (${row},${col})`);
      }

      newGrid[row][col] = plant;
    });

    setGrid(newGrid);
  };

  function renderGridHtml() {
    return grid.map((row, rowIndex) => (
      <div key={rowIndex} className="GridRow">
        {row.map((plant, colIndex) => {
          if (plant) {
            return (
              <PlantModel
                {...{ rowIndex, colIndex, plant, selectingModeActive, handlePlantDivClick, handlePlantSelectClick }}
              />
            );
          } else {
            return (
              <div
                key={`location_[${colIndex},${rowIndex}]`}
                data-col={colIndex + 1}
                data-row={rowIndex + 1}
                className="GridItem nonActive"
                onClick={handleNoPlantDivClick}
              >
                No Plant
              </div>
            );
          }
        })}
      </div>
    ));
  }



  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRemoveSubmit = (e) => {
    e.preventDefault();
    axios
      .delete(`plant/${selectedUid}`)
      .then((res) => {
        alert(`Plant ${selectedUid} was removed`);
        setPlantShowModal(false);
        fetchData();
      })
      .catch((error) => {
        console.error(error);
        alert("Something went wrong! Try Again");
      });
  };

  const handleDestroySubmit = (e) => {
    e.preventDefault();
    const deleteObject = {
      type: "plant",
      page: location.pathname,
      objects: [selectedUid]
    }
    toDeletePage(navigate, deleteObject);

  };

  const handlePlaceSubmit = (e) => {
    e.preventDefault();
    try {
      // First, perform a GET request to check the data
      axios.get(`plant`).then((res) => {

        console.log(res.data)
        if (res.data[inputs.uid - 1].datedestroyed || res.data[inputs.uid - 1] === "") {
          // If datedestroyed exists, alert the user and do not proceed with the patch
          alert(`Cannot update Plant with PlantId: ${inputs.uid} as it is destroyed or it doesn't exist`);
        }
        else {
          // If datedestroyed does not exist, proceed with the patch
          const plantValues = {
            plotid: parseInt(id),
            loc_y: parseInt(selectedRow),
            loc_x: parseInt(selectedCol),
          };

          axios.patch(`plant/${inputs.uid}`, plantValues).then((res) => {
            setNoPlantShowModal(false);
            fetchData();
          });
        }
      });
    } catch (err) {
      console.log(err);
      alert("Something went wrong! Try Again");
    }

  };
  const plantIds = plantsNotInPlot.map((item) => item.uid).join(', ');

  const repeatAction = (repeat) => {
    if (repeat) {
      setSelectedCol(null);
      setSelectedRow(null);
    } else {
      setPlantShowModal(false);
    }
  }
  const toggleShow = () => {
    setPlantShowModal(!PlantShowModal);
  }

  const toggleNoPlantShow = () => {
    setNoPlantShowModal(!NoPlantShowModal);
  }

  const deletePlot = (e) => {
    e.preventDefault();
    // axios
    //   .delete(`/plot/${plot.plotid}`)
    //   .then((res) => {
    //     alert(res.data.message);
    //     setPlantShowModal(false);
    //     fetchData();
    //     goBack()
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     alert(`Something went wrong! ${error.request.response}`);
    //   });

    e.preventDefault();

    const deleteObject = {
      type: "plot",
      page: "/main",
      objects: [plot.plotid]
    }
    toDeletePage(navigate, deleteObject);
  }


  return (
    <div className="bodyContent">
      <h2 className="subheader">Plot {plot.plotno}</h2>
      <br />
      <Link to={`/editPlot/${plot.plotid}`}>
        <input type="button" value="Edit Plot" className="purpleBtn" />
      </Link>
      <input type="button" value={`${selectingModeActive ? "Deselect" : "Select"} Plants`} className="purpleBtn" onClick={handleSelectPlantsClick} />
      <Link to={`/newPlant`}>
        <input type="button" value="New Plant" className="purpleBtn" />
      </Link>
      <form method="POST" onSubmit={deletePlot} className="inlineDeleteBtn">
        <input
          type="submit"
          value="Delete Plot"
          className="deleteBtn roomDeleteBtn"
        />
      </form>
      <div className={`PlantSelectActions ${!selectingModeActive ? "PlantSelectActionsHidden" : ""}`}>
        <button className="purpleBtn" onClick={handleRemoveBulkClick}>
          Remove Plants
        </button>
        <button className="purpleBtn" onClick={hanldeBulkDestroyClick}>
          Destroy Plants
        </button>
        <button className="purpleBtn" onClick={hanldeBulkMaintenanceClick}>
          Maintenance Plants
        </button>
        {bulkMaintenanceShowModal && (
          <MDBModal show={bulkMaintenanceShowModal} setShow={setBulkMaintenanceShowModal} tabIndex="-1" style={{ overflow: "scroll" }}>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                  <button
                      className="close-btn"
                      onClick={closeBulkMaintenanceClick}
                    >
                      <FaTimes />
                    </button>
                    <h1>Bulk Maintenance</h1>
                    <div>Bulk maintance for: {Array.from(selectedUids).join(",")}</div>
                    
                  </MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody>
                  <div>
                    <MaintenanceSelector
                      repeatAction={null}
                      selectedUid={Array.from(selectedUids)}
                      setSelectedUid={null}
                      type="plants"
                      closeHandler={maintenanceModalCloseHandler}
                    />
                  </div>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        )}
      </div>

      {/* <Link to="/replacePlot">
        <input
          type="button"
          value="Replace Plot"
          className="purpleBtn marginTop10"
        />
      </Link> */}

      {/* Make grid dynamic with database values */}

      <div className="Grid" style={{ width: "fit-content" }}>{gridHtml}</div>

      {NoPlantShowModal && (

        <MDBModal show={NoPlantShowModal} setShow={setNoPlantShowModal} tabIndex="-1" style={{ overflow: "scroll" }}>

          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>

                  <div className="">
                    <button
                      className="close-btn"
                      onClick={toggleNoPlantShow}
                    >
                      <FaTimes />
                    </button>

                    <h1 className="title">Place Plant</h1>
                    <div className="centered relativeCentered">
                      <p>Row: {selectedRow} Column: {selectedCol}</p>
                    </div>
                    <label htmlFor="uid">Plant ID</label>

                    <div className="editFormDiv">
                      <form className="editForm" onSubmit={handlePlaceSubmit}>
                        <input
                          type="number"
                          name="uid"
                          id="uid"
                          className="editInput"
                          autoFocus
                          onChange={handleChange}
                        />
                        <br />
                        <input
                          type="submit"
                          value="Place Plant"
                          className="maintenanceFormSubmit"
                        />

                      </form>
                    </div>
                  </div>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>


                <h1>Plants that are not in a plot: </h1>
                <ul style={{ width: "100%", margin: "0px 20px", padding: "0", transform: "translateX(-20px)" }}>
                  {plantIds}
                </ul>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      )}

      {PlantShowModal && (
        <MDBModal show={PlantShowModal} setShow={setPlantShowModal} tabIndex="-1">

          <MDBModalDialog scrollable>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  <div className="">
                    <button
                      className="close-btn"
                      onClick={toggleShow}
                    >
                      <FaTimes />
                    </button>
                    <h1 className="title">Info Plant: {selectedUid}</h1>
                    <p>Row: {selectedRow} Column: {selectedCol}</p>
                  </div>
                  <div className="editFormDiv leftAlign">
                    <form className="editForm smallForm" onSubmit={handleRemoveSubmit}>
                      <input
                        type="submit"
                        value="Remove plant"
                        className="deleteBtn"
                      />
                    </form>
                    <form className="editForm smallForm deleteBtnMargin" onSubmit={handleDestroySubmit}>
                      <input
                        type="submit"
                        value="Destory plant"
                        className="deleteBtn"
                      />
                    </form>
                  </div>
                  <div className="editFormDiv">

                  </div>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
                <div>

                  <MaintenanceSelector type={"plant"} selectedUid={selectedUid} setSelectedUid={setSelectedUid} repeatAction={repeatAction} />
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      )}


    </div>
  );
}


export default requireAuth(Plot);

import React, { useState } from "react";
import requireAuth from "../context/requireAuth";
import axios from "../context/axiosConfig";

function NewPlot() {
  const [formData, setFormData] = useState({
    plotno: "",
    size_x: 0,
    size_y: 0,
    description: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("/plot", formData);
      const plotId = response.data[0].plotid;
      // Handle successful post request
      alert(`Plot created successfully! Plot ID: ${plotId}`);
      setFormData({
        plotno: "",
        size_x: 0,
        size_y: 0,
        description: "",
      });
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };
  

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <div className="bodyContent">
      <h1 className="title">New Plot</h1>
      <div className="editFormDiv">
        <form className="editForm" onSubmit={handleSubmit}>
          <label htmlFor="plotno">Plot Number</label>
          <input
            type="text"
            name="plotno"
            id="plotno"
            className="editInput"
            value={formData.plotno}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="size_x">Size X</label>
          <input
            type="number"
            name="size_x"
            id="size_x"
            className="editInput"
            min={1}
            onChange={handleChange}
         
          />
          <br />
          <label htmlFor="size_y">Size Y</label>
          <input
            type="number"
            name="size_y"
            id="size_y"
            className="editInput"
            min={1}
            onChange={handleChange}
        
          />
          <br />
          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            id="description"
            className="maintenanceInput flexTextBox"
            cols="30"
            rows="10"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
          <br />

          <input
            type="submit"
            value="Create Plot"
            className="maintenanceFormSubmit"
          />
        </form>
      </div>
    </div>
  );
}

export default requireAuth(NewPlot);

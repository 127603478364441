import React from "react";
import yookrLogo from "../photos/logoYookrNieuw.png"

function Footer() {
    
    
    return(
        <div className="footer">
                <p className="poweredText">Powered by:</p>
            <img src={yookrLogo} alt="" className="yookrLogo"/>
        </div>
    )
}

export default Footer;
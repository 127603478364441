import React from 'react';
import axios from "../context/axiosConfig";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(() => {
      const storedToken = sessionStorage.getItem('token');
      const storedAccountId = sessionStorage.getItem('accountid');
      const storedName = sessionStorage.getItem('name');
      if (storedToken && storedAccountId) {
        return { token: storedToken, accountid: storedAccountId };
      }
      return {};
    });
  
    const isAuthenticated = async () => {
      try {
        const res = await axios.get('/checktoken');
        return res.status === 200;
      } catch (error) {
        console.log('token is expired or not stored');
        return false;
      }
    }
  
    const login = async (inputs) => {
      // console.log(inputs)
      const res = await axios.post("login", inputs);
      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('accountid', res.data.accountid);
      const res1 = await axios.post("login", inputs);
      sessionStorage.setItem('token', res1.data.token);

      setCurrentUser({ token: res.data.token, accountid: res.data.accountid, isAuthenticated });
    }
  
    const logout = async () => {
      setCurrentUser(null);
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('accountid');
      sessionStorage.removeItem('name');
    }
  
    useEffect(() => {
      isAuthenticated().then((authenticated) => {
        if (authenticated) {
          setCurrentUser((prevUser) => ({ ...prevUser, isAuthenticated }));
        }
      });
    }, []);
  
    return <AuthContext.Provider value={{ currentUser, login, logout }}>{children}</AuthContext.Provider>
  }
  
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// set base URL
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || 'https://api.innexo.yookr.org/';

// add session headers
axios.interceptors.request.use(config => {
  const token = sessionStorage.getItem('token');
  const accountid = sessionStorage.getItem('accountid');
  if (token && accountid) {
    config.headers.Authorization = token;
    config.headers.accountid = accountid;
  }
  return config;
});

// add interceptor for handling 403 and 401 errors
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403 || error.response.status === 401) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('accountid');
      const navigate = useNavigate();
      navigate('/login');
    }
    return Promise.reject(error);
  }
);

export default axios;

import React from "react";
import { useState } from 'react';
import requireAuth from "../context/requireAuth";
import axios from "../context/axiosConfig";
import { useNavigate } from "react-router-dom";

function NewRoom() {
  const [formData, setFormData] = useState({
    roomid: "49",
    roomlocation: "Greenhouse",
    designation: "Trial room",
    commisionedby: null,
    substrateid: null,
    equipmentid: null,
    ipmid: null,
    projectcode: "Api",
    cycleperiod: "12/12",
    description: "Platform",
    size_y: 4,
    size_x: 4,
    size_z: 1
  });
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    axios.post('/room', formData)
      .then(response => {
        console.log(response.data);
        // Handle success response
        navigate('/roomselect')
        
      })
      .catch(error => {
        console.error(error);
        alert('Something went wrong. Please try a different ID')
      });
  }
  
  function handleChange(event) {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }   

  return (
    <div className="bodyContent">
      <h1 className="title">New Room</h1>
      <div className="editFormDiv">
        <form className="editForm" onSubmit={handleSubmit}>
          <label htmlFor="roomid">Room ID</label>
          <input type="text" name="roomid" id="roomid" className="editInput" value={formData.roomid} onChange={handleChange} />
          <br />
          <label htmlFor="roomlocation">Room Location</label>
          <input type="text" name="roomlocation" id="roomlocation" className="editInput" value={formData.roomlocation} onChange={handleChange} />
          <br />
          <label htmlFor="designation">Designation</label>
          <input type="text" name="designation" id="designation" className="editInput" value={formData.designation} onChange={handleChange} />
          <br />
          <label htmlFor="projectcode">Project Code</label>
          <input type="text" name="projectcode" id="projectcode" className="editInput" value={formData.projectcode} onChange={handleChange} />
          <br />
          <label htmlFor="cycleperiod">Cycle Period</label>
          <input type="text" name="cycleperiod" id="cycleperiod" className="editInput" value={formData.cycleperiod} onChange={handleChange} />
          <br />
          <label htmlFor="description">Description</label>
          <input type="text" name="description" id="description" className="editInput" value={formData.description} onChange={handleChange} />
          <br />
          <label htmlFor="size_y">Size Y</label>
          <input type="number" name="size_y" id="size_y" className="editInput" value={formData.size_y} onChange={handleChange} />
          <br />
          <label htmlFor="size_x">Size X</label>
          <input type="number" name="size_x" id="size_x" className="editInput" value={formData.size_x} onChange={handleChange} />
          <br />
          <label htmlFor="size_z">Size Z</label>
          <input type="number" name="size_z" id="size_z" className="editInput" value={formData.size_z} onChange={handleChange} />
  
          <input type="submit" value="Create Room" className="maintenanceFormSubmit" />
        </form>
      </div>
      </div>
  )
};

export default requireAuth(NewRoom);
import React from "react";
import requireAuth from "../context/requireAuth";

function EditAccount() {
    return(
        <div className="bodyContent">

                <div className="editFormDiv">
                <form action="/home" method="post" className="editForm">
                    <label htmlFor="oldPw">Old Password</label>
                    <input type="password" name="oldPw" id="oldPw" className="editInput topInput"/>
                    <br />
                    <label htmlFor="newPw">New Password</label>
                    <input type="password" name="newPw" id="newPw" className="editInput" />
                    <br />
                    <label htmlFor="reEnter">Re-enter New Password</label>
                    <input type="password" name="reEnter" id="reEnter" className="editInput" />

                    <input type="submit" value="Change Password" className="maintenanceFormSubmit"/>
                </form>
                </div>
            </div>
    )
}

export default requireAuth(EditAccount);
import React, { useContext, useEffect, useState } from "react";
import logo from "../photos/logo.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const navigate = useNavigate();
  const { currentUser, login } = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(inputs);
      const redirectTo = sessionStorage.getItem("redirectTo") || "/";
      sessionStorage.removeItem("redirectTo");
      navigate(redirectTo);
    } catch (err) {
      console.log(err);
      alert("Wrong password or username!");
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.isAuthenticated) {
      const redirectTo = sessionStorage.getItem("redirectTo") || "/";
      sessionStorage.removeItem("redirectTo");
      navigate(redirectTo);
    }
  }, [currentUser]);

  return (
    <div className="bodyContent justified">
      <div className="centered">
        <img src={logo} alt="Logo Innexo" className="block logoBig" />
        <form action="/" method="post" className="loginForm">
          <div className="username">
            <p className="loginText">Username</p>
            <input
              type="text"
              name="username"
              id="username"
              className="loginInput"
              onChange={handleChange}
              autoComplete="username"
            />
          </div>
          <div className="password">
            <p className="loginText">Password</p>
            <div className="passwordInputContainer">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                className="loginInput"
                onChange={handleChange}
                autoComplete="current-password"
              />
              {showPassword ? (
                <FaEyeSlash
                  className="passwordToggleIcon"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <FaEye
                  className="passwordToggleIcon"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
          </div>
          <input
            type="submit"
            value="Login"
            className="block loginBtn"
            onClick={handleSubmit}
          />
          <div className="rememberMe">
            <input type="checkbox" name="RembemberMe" id="member" />
            <p className="inline-block memberText">Remember Me</p>
          </div>
        </form>
        </div>
      </div>
  );
}

export default Login;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context/authContext';
import ErrorHandler from './errorHandler/errorHandler';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ErrorHandler>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ErrorHandler>
  </React.StrictMode>
);

reportWebVitals();

import { useEffect, useMemo, useState } from "react";
import axios from '../context/axiosConfig';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { FaTimes } from "react-icons/fa";

const categories = {
  "Watering": {
    "name": "Watering",
    "sublevel": ["Mutual Irrigation", "Automated Irrigation", "Nutrient Schedule", "Other"]
  },
  "Inspection": {
    "name": "Inspection",
    "sublevel": ["General", "Growth", "Architecture", "Nodal Spacing", "Leaf Formation, Mutations", "Nutrient Deficiencies", "Rooting", "Sex", "Emergence", "Organoleptic", "Trichomes", "Other"]
  },
  "Scouting": {
    "name": "Scouting",
    "sublevel": ["General", "Fungus Gnats", "Powdry Mildew", "Botrytis", "Rust", "Viroid", "Deformations", "Spider Mites", "Caterpillars", "White Fly", "Root Aphid", "Lice", "Other"]
  },
  "Manipulation": {
    "name": "manipulation",
    "sublevel": ["Pruning", "Removing Leafs", "Training", "Bending", "Scrogg", "Topping", "Fimming", "Other"]
  },
  "Destruction": {
    "name": "Destruction",
    "sublevel": ["Whole Plant", "Part of Plant", "Infected Flower", "Unrooted Clone", "Rooted Clone", "Mother Plant", "Back-up Plant", "Other"]
  },
  "Harvesting": {
    "name": "Harvesting",
    "sublevel": ["Whole Plant", "Part of Plant", "Main Flower", "Side Flower", "Lower Branches", "Mid Branches", "Upper Branches", "Other"]
  },
  "Measurement": {
    "name": "Measurement",
    "sublevel": ["Height", "Width", "Internodal Length", "# Nodes", "# Flower Sites", "PPFD", "Sampling Irrigation", "Sampling Drain", "Sampling Flower", "Sampling Leaf", "Other"]
  },
  "Application": {
    "name": "Application",
    "sublevel": ["Pesticide", "Fungicide", "Biostimulant", "Other"]
  },
  "Infection": {
    "name": "Infection",
    "sublevel": ["Fungus Gnats", "Powdry Mildew", "Botrytis", "Rust", "Viroid", "Deformations", "Spider Mites", "Caterpillars", "White Fly", "Root Aphid", "Lice", "Other"]
  },
  "Planting Seed": {
    "name": "Planting Seed",
    "sublevel": ["Regular", "Female", "Autoflower", "Other"]
  },
  "Cutting Clone": {
    "name": "Cutting Clone",
    "sublevel": ["Trial Plant", "Mother Plant", "Back-up", "Other"]
  },
  "Transplanting": {
    "name": "Transplanting",
    "sublevel": ["To Trial Site", "To Mother Room", "To Propagation Room", "To Larger Volume Substrate", "Other"]
  },
  "Adjusting": {
    "name": "Adjusting",
    "sublevel": ["Climate", "Nutrient Solution", "Lighting Period", "Screening", "Air Movement", "Plant Height", "Irrigation Schedule", "Other"]
  },
  "Documentation": {
    "name": "Documentation",
    "sublevel": ["Pictures", "Video", "Time-Lapse", "Macro Shots", "360 Shots", "Other"]
  },
  "Administration": {
    "name": "Administration",
    "sublevel": ["# Plants"]
  }
}


const categoryKeys = Object.keys(categories)


export function MaintenanceSelector({ selectedUid, setSelectedUid, repeatAction, closeHandler, type }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSublevel, setSelectedSublevel] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSev, setSelectedSev] = useState("");
  const [desc, setDesc] = useState("");
  const [isEditable, setIsEditable] = useState(false);

  const bulkModeEnabled = useMemo(() => {
    return type === "plants"
  }, [type])

  const [showRepeatModal, setShowRepeatModal] = useState(false);

  useEffect(() => () => {
    setSelectedCategory(null); // Reset selectedCategory when modal closes
    setSelectedSublevel(null); // Reset selectedSublevel when modal
  }, [])


  const handleCategoryClick = (key) => {
    setSelectedCategory(key);
  }

  const handleCategoryBackClick = () => {
    setSelectedCategory("");
  }

  const handleSublevelClick = (sublevel) => {
    setSelectedSublevel(sublevel);
  };

  const handleSublevelBackClick = () => {
    setSelectedSublevel(null)
  }

  const handleOptionsChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
  }

  const handleOptionsSevChange = (event) => {
    const newValue = parseInt(event.target.value);
    setSelectedSev(newValue);
  }

  const handleDescChange = (e) => {
    setDesc(e.target.value);
  }
  const toggleRepeatShow = () => {
    setShowRepeatModal(!showRepeatModal);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();


    const data = {
      category: selectedCategory,
      performance: selectedSublevel,
      description: desc,
      alarmlevel: selectedOption,
      severity: parseInt(selectedSev),
    };

    console.log(data)
    console.log(selectedUid)

    if (bulkModeEnabled) {
      data["uids"] = selectedUid;

      axios.post(`maintenance`, data)
        .then((res) => {
          if (res && res.status === 200) {
            console.log(res.data)
          } else {
            console.error("Invalid response:", res);
          }
          
          closeHandler()
        }).catch((error) => {
          // Handle network or other errors
          console.error("Error occurred:", error);
          closeHandler()
        })

    } else {
      data["uid"] = selectedUid
      axios.post(`maintenance/${type}/${selectedUid}`, data)
        .then((res) => {
          if (res && res.status === 200) {
            console.log(res.data)
          } else {
            console.error("Invalid response:", res);
          }
        }).catch((error) => {
          // Handle network or other errors
          console.error("Error occurred:", error);
        })
      setShowRepeatModal(true);
    }

  };

  const handleRepeatAction = (repeat) => {

    repeatAction(repeat)
    if (repeat) {
      // Clear the UID input or any other fields that need to be reset
      setSelectedUid("");
      setIsEditable(true);

      // Close the "Repeat Action" modal
      setShowRepeatModal(false);
    } else {
      // Close the form or perform any other necessary action
      // Reset any other form-related state variables
      setIsEditable(false);
      setShowRepeatModal(false);
    }
  };





  return (
    <>
      {!selectedCategory && (
        <div className="maintenaceGrid">
          {categoryKeys.map((key) => {
            return (
              <div className="purpleBtn extraGridBtn" key={key} onClick={() => handleCategoryClick(key)}>{key}</div>
            )
          })}
        </div>
      )}
      {selectedCategory && !selectedSublevel && (
        <>
          <h3>{categories[selectedCategory].name}</h3>
          <div className="maintenaceGrid">
            <div className="purpleBtn extraGridBtn blueBtn" onClick={() => handleCategoryBackClick()}>
              Back
            </div>
            {categories[selectedCategory].sublevel.map((sub) => (
              <div className="purpleBtn extraGridBtn" key={sub} onClick={() => handleSublevelClick(sub)}>{sub}</div>
            ))}

          </div>
        </>
      )}

      {selectedSublevel && (
        <>
          <div className="purpleBtn extraGridBtn blueBtn" onClick={() => handleSublevelBackClick()}>
            Back
          </div>
          <h3>Selected Top level maintenance: {selectedCategory}</h3>
          <h3>Selected Sub level maintenance: {selectedSublevel}</h3>
          <div className="editFormDiv">
            <form className="editForm" onSubmit={handleSubmit}>
              {!bulkModeEnabled ? (
                <div className="maintenanceFormElement">
                  <label htmlFor="uid">UID</label>
                  <input type="text" name="uid" id="uid" className="maintenanceInput" value={selectedUid} readOnly={!isEditable} onChange={(event) => {
                    if (isEditable) {
                      setSelectedUid(event.target.value);
                    }
                  }} />
                </div>
              ) : ""}
              <br />

              <div className="descFlex">
                <label htmlFor="desc">Description</label>
                <textarea type="text" name="desc" id="desc" className="maintenanceInput flexTextBox" cols="30" rows="10" onChange={handleDescChange}></textarea>
              </div>

              <div className="maintenanceFormElement">
                <label htmlFor="alarmLvl">Alarm Level</label>
                <select name="alarmLvl" id="alarmLvl" className="maintenanceInput" onChange={handleOptionsChange} value={selectedOption}>
                  <option value="select...">Select...</option>
                  <option value="0">Low Alert</option>
                  <option value="1">Medium Alert</option>
                  <option value="2">High Alert</option>
                </select>
              </div>

              <div className="maintenanceFormElement">
                <label htmlFor="sevLvl">Severity Level</label>
                <select name="sevLvl" id="sevLvl" className="maintenanceInput" onChange={handleOptionsSevChange} value={selectedSev}>
                  <option value="select...">Select...</option>
                  <option value="0">1</option>
                  <option value="1">2</option>
                  <option value="2">3</option>
                  <option value="3">4</option>
                  <option value="4">5</option>
                  <option value="5">6</option>
                  <option value="6">7</option>
                  <option value="7">8</option>
                  <option value="8">9</option>
                  <option value="9">10</option>
                </select>
              </div>

              <input type="submit" value="Submit" className="maintenanceFormSubmit" style={{ marginTop: "30px" }} />
            </form>
          </div>
        </>
      )}
      {(showRepeatModal && !bulkModeEnabled) && (
        <MDBModal show={showRepeatModal} setShow={setShowRepeatModal} tabIndex="-1">

          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  <button
                    className="close-btn"
                    onClick={toggleRepeatShow}
                  >
                    <FaTimes />
                  </button>
                  <h1 className="title">Repeat this action for another {type}?</h1>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
                <div class="centerFlex">
                  <input type="button" value="Yes" className="maintenanceFormSubmit longBtn longBtnTopMargin" onClick={() => handleRepeatAction(true)} />
                  <br />
                  <input type="button" value="No" className="maintenanceFormSubmit longBtn" onClick={() => handleRepeatAction(false)} />
                </div>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      )}
    </>
  )
}
import React, { useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../photos/logo.png";
import { AuthContext } from "../context/authContext";

function Navbar() {
    const { logout } = useContext(AuthContext); // Updated to use lowercase "o"

    const location = useLocation();
    // console.log(location);
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    function handleLogout() { // Renamed to handleLogout
        logout();
        navigate("/login");
    }

    return (
        <div className="navbar">
            {location.pathname === "/" || location.pathname === "/login" ? <div className="backBtnFill"></div> : (
                <input type="button" value="Back" onClick={goBack} className="backBtn button" />
            )}
            <NavLink to="/">
                <img src={logo} alt="innexoLogo" className="logo" />
            </NavLink>
            {location.pathname === "/login" ? null : (
                <NavLink to="/login">
                    <input type="button" value="Logout" className="logoutBtn button" onClick={handleLogout} />
                </NavLink>
            )}
        </div>
    );
}

export default Navbar;
import React, { useState, useEffect } from "react";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import { Link, useParams, useNavigate } from "react-router-dom";

function EditPlot({ match }) {
  const [plotData, setPlotData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlotData = async () => {
        
      try {
        const response = await axios.get(`/plot/plotid?plotid=${id}`);
        setPlotData(response.data[0]);
      } catch (error) {
        // Handle error
      }
    };

    fetchPlotData();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.patch(`/plot/${id}`, {
        plotno: event.target.plotNo.value,
        size_x: event.target.cols.value,
        size_y: event.target.rows.value,
        description: event.target.desc.value
      });
      // Handle successful patch request
    } catch (error) {
      // Handle error
    }
    navigate(-1);
  };

  return plotData ? (
    <div className="bodyContent">
      <h1 className="title">Edit Plot {id}</h1>
      <div className="editFormDiv">
        <form onSubmit={handleSubmit} className="editForm">
          <label htmlFor="plotNo">Plot Number</label>
          <input
            type="number"
            name="plotNo"
            id="plotNo"
            className="editInput"
            defaultValue={plotData.plotno}
          />
          <br />
          <label htmlFor="cols">Columns</label>
          <input
            type="number"
            name="cols"
            id="cols"
            className="editInput"
            defaultValue={plotData.size_x}
          />
          <br />
          <label htmlFor="rows">Rows</label>
          <input
            type="number"
            name="rows"
            id="rows"
            className="editInput"
            defaultValue={plotData.size_y}
          />
          <br />
          <div className="descFlex">
            <label htmlFor="desc">Description</label>
            <textarea
              name="desc"
              id="desc"
              className="maintenanceInput flexTextBox"
              cols="30"
              rows="10"
              defaultValue={plotData.description}
            ></textarea>
          </div>

          <input type="submit" value="Edit Plot" className="maintenanceFormSubmit" />
        </form>
      </div>
    </div>
  ) : (
    <div>Loading plot data...</div>
  );
}

export default requireAuth(EditPlot);

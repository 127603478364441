import React, { useEffect } from "react";
import { useState } from 'react';
import requireAuth from "../context/requireAuth";
import axios from "../context/axiosConfig";

function NewProduct() {

    const now = new Date(Date.now()).toISOString().split('T')[0];
    
    const [formData, setFormData] = useState({
        name: '',
        dateCreated: now,
        fresh: 'Y',
        dry: 'Y',
        location: 'vault'
    });
  
    function handleSubmit(event) {
      event.preventDefault();
      axios.post('/product', formData)
        .then(response => {
          console.log(response.data);
          const productCreated = response.data.map(product => product.upid);
          alert(`Product created with upid: ${productCreated.join(', ')}`);
          setFormData({
            name: '',
            dateCreated: now,
            quantity: 1,
            fresh: 'Y',
            dry: 'Y',
            location: 'vault'
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
    
  
    function handleChange(event) {
      setFormData({ ...formData, [event.target.name]: event.target.value });
      console.log(formData)
    }   

    return (
        <div className="bodyContent">
            <h1 className="title">New Product</h1>
            <div className="editFormDiv">
              <form className="editForm" onSubmit={handleSubmit}>
              {/* <label htmlFor="upid">UPID</label>
                <input type="number" name="upid" id="upid" min="1" max="10000000000" className="editInput" value={formData.upid} onChange={handleChange} />
                <br /> */}
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="variety" className="editInput" value={formData.name} onChange={handleChange} />
                <br />
                <label htmlFor="dateCreated">Creation Date</label>
                <input type="date" name="dateCreated" id="dateCreated" className="editInput" value={now} onChange={handleChange} />
                <br />
                <label htmlFor="qty">Quantity in grams</label>
                <input type="number" name="qty" id="qty" className="editInput" value={formData.quantity} min="1" max="10000" onChange={handleChange} />
                <br />
                <label htmlFor="fresh">Fresh (Yes/No)</label>
                <select name="fresh" id="fresh" className="editInput editSelect" value={formData.fresh} onChange={handleChange}>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <br />
                <label htmlFor="dry">Dry (Yes/No)</label>
                <select name="dry" id="dry" className="editInput editSelect" value={formData.dry} onChange={handleChange}>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <br />
                <label htmlFor="location">Location</label>
                <select name="location" id="location" className="editInput editSelect" value={formData.location} onChange={handleChange}>
                    <option value="vault">Vault</option>
                    <option value="drying">Drying</option>
                </select>
                <br />
                <input type="submit" value="Create Product" className="maintenanceFormSubmit" />
              </form>
            </div>
          </div>
      );
    }

export default requireAuth(NewProduct);
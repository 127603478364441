import React from "react";
import { Link } from "react-router-dom";
import requireAuth from "../context/requireAuth";

function CreateNew() {
    
    return(
        <div className="bodyContent">
               <h1 className="title"> Create New </h1>
                <div className="homeGrid">
                    <Link to="/newPlant" className="gridItem1">
                    <div className="purpleBox">
                        <p className="linkText">New Plant</p>
                    </div>
                    </Link>
                    <Link to="/newPlot" className="gridItem2">
                    <div className="purpleBox">
                        <p className="linkText">New Plot</p>
                    </div>
                    </Link>
                    <Link to="/newRoom" className="gridItem3">
                        <div className="purpleBox">
                            <p className="linkText">New Room</p>
                        </div>
                    </Link>
                    <Link to="/newAccount" className="gridItem4">
                    <div className="purpleBox">
                        <p className="linkText">New Account</p>
                    </div>
                    </Link>
                    <Link to="/newProduct" className="gridItem5">
                    <div className="purpleBox">
                        <p className="linkText">New Product</p>
                    </div>
                    </Link>
                </div>
            </div>
    )
}

export default requireAuth(CreateNew);
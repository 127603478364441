import requireAuth from "../context/requireAuth";
import { useState, useEffect, useRef, useCallback } from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import axios from "../context/axiosConfig";
import ReactSignatureCanvas from "react-signature-canvas";

const canvasWidth = 300;
const canvasProps = { width: canvasWidth, height: canvasWidth * (3 / 5) }
const columns = [
    {
        field: "id"
    },
    {
        field: "deletionType",
        headerName: "Type",
        width: 200
    },
    {
        field: "deletionUid",
        headerName: "deleted object uid",
        width: 140,
    },
    {
        field: "created",
        headerName: "created",
        width: 350,
        valueFormatter: (value) => {
            const dateValue = new Date(value)
            return `${dateValue.toDateString()}/${dateValue.getHours()}:${dateValue.getMinutes()}:${dateValue.getSeconds()}`
        }
    },
    {
        field: "signature1",
        headerName: "Signature 1",
        renderCell: (params) => {
            return <img
                src={params.value}
                alt="signature"
                width={canvasProps.width}
                height={canvasProps.height}
            />
        },
        width: canvasProps.width,
        sortable: false
    },
    {
        field: "signature2",
        headerName: "Signature 2",
        renderCell: (params) => {
            return <img
                src={params.value}
                alt="signature"
                width={canvasProps.width}
                height={canvasProps.height}
            />
        },
        width: canvasProps.width,
        sortable: false
    }
]

function Signatures() {
    const [signatures, setSignatures] = useState([]);
    useEffect(() => {
        axios.get("signature")
            .then(res => setSignatures(res.data))
            .catch(err => console.log(err));
    }, []);



    return (
        <div className="bodyContent">
            <div className="plantTableDiv">
            <DataGrid
                columns={columns}
                rows={signatures}
                rowHeight={canvasProps.height}
                slots={{
                    toolbar: GridToolbar
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false
                        }
                    },
                    sorting: {
                        sortModel: [{field: "id", sort: "desc"}]
                    },
                }}
                getCellClassName={() => "fontSizeSignatureGrid"}
            />
            </div>
        </div>

    )
}

export default requireAuth(Signatures);
import React, { useState, useEffect } from "react";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import { useParams, useNavigate } from "react-router-dom";

function EditRoom() {
    const [roomData, setRoomData] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRoomData = async () => {
            try {
                const response = await axios.get(`/room/roomid?roomid=${id}`);
                setRoomData(response.data[0]);
            } catch (error) {
                // Handle error
            }
        };

        fetchRoomData();
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.patch(`/room/${id}`, {
                roomlocation: event.target.roomLocation.value,
                designation: event.target.designation.value,
                // commisionedby: null,
                // substrateid: null,
                // equipmentid: null,
                // ipmid: null,
                projectcode: event.target.projectCode.value,
                cycleperiod: event.target.cyclePeriod.value,
                description: event.target.description.value,
                size_x: event.target.sizeX.value,
                size_y: event.target.sizeY.value,
                size_z: event.target.sizeZ.value,
            });
            // Handle successful patch request
        } catch (error) {
            // Handle error
        }
        navigate(-1);
    };

    return roomData ? (
        <div className="bodyContent">
            <h1 className="title">Edit Room {id}</h1>
            <div className="editFormDiv">
                <form onSubmit={handleSubmit} className="editForm">
                    <label htmlFor="roomLocation">Room Location</label>
                    <input
                        type="text"
                        name="roomLocation"
                        id="roomLocation"
                        className="editInput"
                        defaultValue={roomData.roomlocation}
                        readOnly
                    />
                    <br />
                    <label htmlFor="designation">Designation</label>
                    <input
                        type="text"
                        name="designation"
                        id="designation"
                        className="editInput"
                        defaultValue={roomData.designation}
                    />
                    <br />
                    <label htmlFor="projectCode">Project Code</label>
                    <input
                        type="text"
                        name="projectCode"
                        id="projectCode"
                        className="editInput"
                        defaultValue={roomData.projectcode}
                    />
                    <br />
                    <label htmlFor="cyclePeriod">Cycle Period</label>
                    <input
                        type="text"
                        name="cyclePeriod"
                        id="cyclePeriod"
                        className="editInput"
                        defaultValue={roomData.cycleperiod}
                    />
                    <br />
                    <label htmlFor="description">Description</label>
                    <textarea
                        name="description"
                        id="description"
                        className="maintenanceInput flexTextBox"
                        cols="30"
                        rows="10"
                        defaultValue={roomData.description}
                    ></textarea>
                    <br />
                    <label htmlFor="sizeX">Size X</label>
                    <input
                        type="number"
                        name="sizeX"
                        id="sizeX"
                        className="editInput"
                        defaultValue={roomData.size_x}

                    />
                    <br />
                    <label htmlFor="sizeY">Size Y</label>
                    <input
                        type="number"
                        name="sizeY"
                        id="sizeY"
                        className="editInput"
                        defaultValue={roomData.size_y}
   
                    />
                    <br />
                    <label htmlFor="sizeZ">Size Z</label>
                    <input
                        type="number"
                        name="sizeZ"
                        id="sizeZ"
                        className="editInput"
                        defaultValue={roomData.size_z}

                    />
                    <br />

                    <input type="submit" value="Edit Room" className="maintenanceFormSubmit" />
                </form>
            </div>
        </div>
    ) : (
        <div>Loading room data...</div>
    );
}
export default requireAuth(EditRoom);


import React from "react";
import requireAuth from "../context/requireAuth";

function NewAccount() {
    return(
        <div className="bodyContent">
            <h1 className="title">New Account</h1>
                <div className="editFormDiv">
                <form action="/home" method="post" className="editForm">
                    <label htmlFor="username">Username</label>
                    <input type="text" name="username" id="username" className="editInput" />
                    <br />
                    <label htmlFor="pw">Password</label>
                    <input type="password" name="pw" id="pw" className="editInput" />
                    <br />
                    <label htmlFor="fn">First Name</label>
                    <input type="text" name="fn" id="fn" className="editInput" />
                    <br />
                    <label htmlFor="ln">Last Name</label>
                    <input type="text" name="ln" id="ln" className="editInput" />
                    <br />
                    <label htmlFor="email">E-Mail</label>
                    <input type="email" name="email" id="email" className="editInput" />
                    <br />
                    <label htmlFor="role">Work Role</label>
                    <select name="role" id="role" className="editInput editSelect">
                        <option value="leader">Project Leader</option>
                        <option value="member">Project Member</option>
                    </select>

                    <input type="submit" value="Create Account" className="maintenanceFormSubmit"/>
                </form>
                </div>
            </div>
    )
}

export default requireAuth(NewAccount);
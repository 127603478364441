import { Button, Grid } from "@mui/material";
import React from "react";
import SignatureCanvas from 'react-signature-canvas';

export default function SignatureGrid({ signature1Ref, signature2Ref }) {
    return (
        <Grid container gap={2} justifyContent="space-evenly">
            <Grid item>
                <h3>Signature 1 - <Button variant="contained" color="error" onClick={() => signature1Ref.current.clear()}>Clear</Button></h3>
                <SignatureCanvas canvasProps={{ width: 500, height: 300, className: 'signatureCanvas' }} ref={signature1Ref} />
            </Grid>
            <Grid>
                <h3>Signature 2 - <Button variant="contained" color="error" onClick={() => signature2Ref.current.clear()}>Clear</Button></h3>
                <SignatureCanvas canvasProps={{ width: 500, height: 300, className: 'signatureCanvas' }} ref={signature2Ref} />

            </Grid>
        </Grid>
    )

}
import React, { useState, useEffect, useMemo } from "react";
import axios from "../context/axiosConfig";
import requireAuth from "../context/requireAuth";
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/FileDownload';

import { ExportToCsv } from 'export-to-csv';

function Maintenance() {

  const [maintenance, setMaintenance] = useState([]);
  useEffect(() => {
    axios.get("maintenance")
      .then(res => setMaintenance(res.data))
      .catch(err => console.log(err));
  }, []);

  console.log(maintenance);

  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    axios.get("room")
      .then(res => setRooms(res.data))
      .catch(err => console.log(err));
  }, []);

  const [plots, setPlots] = useState([]);
  useEffect(() => {
    axios.get("plot")
      .then(res => setPlots(res.data))
      .catch(err => console.log(err));
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'maintenanceid',
        header: 'Maintenance ID',
      },
      {
        accessorKey: 'uid',
        header: 'Plant ID',
      },
      {
        accessorKey: 'category',
        header: 'Type Maintenance',
      },
      {
        accessorKey: 'performance',
        header: 'Sub-Level Maintenance',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'alarmlevel',
        header: 'Alarm Level',
      },
      {
        accessorKey: 'severity',
        header: 'Severity Level',
      },
      {
        accessorKey: 'maintenancedate',
        header: 'Maintenance Date',
        Cell: ({ cell }) => {
          const date = cell.row.original.maintenancedate;
          return date ? new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }) : 'NO DATE';
        }
      },
    ],
    [],
  );


  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    fields: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(maintenance);
  };

  return (
    <div className="bodyContent">
      <label htmlFor="plants" className="subheader">Maintenance Overview</label>

      <div className="plantTableDiv">
        <MaterialReactTable columns={columns} data={maintenance} enableRowSelection enableColumnOrdering
          initialState={{
            showColumnFilters: true,
            pagination: { pageIndex: 0, pageSize: 100 },
            sorting: [{
              id: "maintenanceid",
              desc: true 
            }]
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
            </Box>
          )}
        />
      </div>
    </div>
  )
}

export default requireAuth(Maintenance);
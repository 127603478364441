import React from "react";
import requireAuth from "../context/requireAuth";

function ReplacePlot() {
    return (
        <div className="bodyContent">
            <h1 className="title">Replace Plot [plotID]</h1>
            <div className="editFormDiv">
                <form action="/home" method="post" className="editForm">
                    <label htmlFor="roomNo">Room Number</label>
                    <select name="roomNo" id="roomNo" className="editInput topInput editSelect">
                        <option value="1">48</option>
                        <option value="2">81</option>
                        <option value="3">82</option>
                    </select>
                    <br />
                    <label htmlFor="cols">Columns</label>
                    <input type="number" name="cols" id="cols" className="editInput" />
                    <br />
                    <label htmlFor="rows">Rows</label>
                    <input type="number" name="rows" id="rows" className="editInput" />
                    <br />
                    <label htmlFor="floor">Floor</label>
                    <input type="number" name="floor" id="floor" className="editInput" />

                    <input type="submit" value="Edit Plot" className="maintenanceFormSubmit" />
                </form>
            </div>
        </div>
    )
}

export default requireAuth(ReplacePlot);